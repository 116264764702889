import { InjectionToken } from '@angular/core';
import { StackData } from '../models/stack';
import { Observable } from 'rxjs';
import { contextes } from '../enums/contextes';
import { GitRepo } from '../models/git-repo';
import { Project } from '../models/project';
import { Action } from '../models/action';
import { Commit, Service } from '../models/service';
import { GitProvider } from '../models/git-provider';

export interface ServiceConfigParam {
    internalOnly?: boolean;
}

export interface DomainConfigRequest {
    path: string;
    prefix: string;
    isRoot: boolean;
    serviceId: string;
}

// Définir un token pour l'injection
export const API_SERVICE_TOKEN = new InjectionToken<ApiServiceInterface>(
    'ApiServiceInterface'
);

export interface ApiServiceInterface {
    quiSuisje?(): Observable<any>;
    quiSuisjeKeycloak?(): Observable<any>;
    inviteUserKeycloak?(email: string): Observable<any>;
    listUsersKeycloak?(): Observable<any>;
    connect?(
        provider: string,
        finalPath: string,
        options: { params? }
    ): Observable<any>;
    reportUrlForIframe?(
        service_id: string,
        context: contextes,
        report_id: string
    ): string;
    generateReport?(service_id: string, context: contextes): Observable<any>;
    getRepos?(provider: string): Observable<any>;
    getBranchesOAuth?(repo: GitRepo): Observable<any>;
    getBranches?(service: Service): Observable<any>;
    setBranch?(
        service: Service,
        context: contextes | string,
        branchName: string
    ): Observable<any>;
    setDisplayName?(service: Service, displayName: string): Observable<any>;
    getProject?(project: Project): Observable<any>;
    getTechno?(repo: GitRepo): Observable<any>;
    addProject?(
        projectName: string,
        stack: StackData,
        gitProvider: GitProvider
    ): Observable<any>;
    addService?(
        project: Project,
        serviceName: string,
        technoId: number
    ): Observable<any>;
    getS3UrlForFileInput?(action: Action, key: string): Observable<any>;
    sendS3FileFromPrePost?(
        prepost: { url: string; fields: { [key: string]: string } },
        file: File
    ): Observable<any>;
    sendInputs?(
        action: Action,
        inputs: { [key: string]: string }
    ): Observable<any>;
    deploy?(service: Service, commit: Commit, context: string): Observable<any>;
    getCommits?(
        service: Service,
        context: string,
        firstCommit?: number,
        nbCommitToGet?: number
    ): Observable<any>;
    getActions?(
        service?: Service,
        otherFilter?: { [key: string]: string }
    ): Observable<any>;
    getDnsUpdateAction?(project: Project): Observable<any>;
    watchAction?(action: Action): Observable<any>;
    getActionLog?(action: Action): Observable<any>;
    getDiffBranch?(
        service: Service,
        context: contextes,
        origin: string,
        destination: string
    ): Observable<any>;
    mergeBranch?(
        service: Service,
        context: contextes,
        origin: string,
        destination: string
    ): Observable<any>;
    mergeBranchWithCommit?(
        service: Service,
        context: contextes,
        origin: string,
        destination: string,
        commitId: string
    ): Observable<any>;
    getGitWeburl?(service: Service, branchName?: string): Observable<any>;
    getMergeRequestsList?(
        service: Service,
        destination: string
    ): Observable<any>;
    getMergeRequestsFromList?(
        service: Service,
        basebranch: string
    ): Observable<any>;
    getUrl?(service: Service, context: contextes): Observable<any>;
    getConfigManagerToolUrl?(
        service?: Service,
        context?: contextes
    ): Observable<any>;
    getUsersProfile?(ids: string[]): Observable<any>;
    getAccounts?(): Observable<any>;
    updateAccess?(role: any): Observable<any>;
    addNewDomain?(project: Project, domainName: string): Observable<any>;
    setAsMainDomain?(project: Project, domainName: string): Observable<any>;
    removeDomain?(project: Project, domainName: string): Observable<any>;
    changeDomain?(project: Project, domainName: string): Observable<any>;
    changeServicesDomainConfig?(
        project: Project,
        newDomainConfig: DomainConfigRequest[]
    ): Observable<any>;
    cancelRequest?(project: Project, serviceId: string): Observable<any>;
    cancelDnsUpdateAction?(project: Project): Observable<any>;
    getTemplates?(): Observable<any>;
    getTechnos?(): Observable<any>;
    getService?(service: Service): Observable<any>;
    getServiceConfig?(service: Service): Observable<ServiceConfigParam>;
    replayAction?(action: Action): Observable<any>;
}
